<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary title-kalam">
            Finance
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Finance! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
            >
              <div class="d-flex justify-content-between">
                <label for="email">Email</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <div class="d-flex justify-content-between">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <div class="d-flex justify-content-between">

              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div class="d-flex justify-content-between">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </div>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login()"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'register'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Auth from '@/api/services/Auth.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'LoginView',
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email
    }
  },
  methods: {
    login () {
      this.$loading(true)
      Auth.login({
        'email': this.userEmail,
        'password': this.password
      }).then(response => {
        this.$store.commit('portal/setLogin', response.data)
        Auth.me().then(response => {
          this.$store.commit('portal/setProfile', response.data.results[0])
          this.$loading(false)
          this.$router.push('/')
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "Welcome to Finance! 👋",
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
          })
        }).catch(error => {
          Auth.logout()
          this.$loading(false)
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: "We couldn't sign you in. Please try again.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
          })
        })
      }).catch((error) => {
        this.$loading(false)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            text: error.response.data.detail,
            variant: 'danger',
          },
        },
        {
          position: 'bottom-center',
        })
      })
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
